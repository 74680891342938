<template>
  <v-container>
    <v-layout column>
      <h3 class="headline grey--text text-xs-center">
        Dashboard: Internal Support
      </h3>
    </v-layout>
  </v-container>
</template>

<script>
export default {
  created: function () {
    this.$router.push('/support/subscription_list')
  },
}
</script>
